<template>
  <p class="title-p" style="width: 100%;">个人中心</p>
  <el-row :gutter="20" style="height: calc(100% - 31px)">
    <el-col :span="6">
      <div class="item-car">
        <div class="item-title-line">
          个人信息
        </div>
        <div style="width: 100%" class="user-icon">
          <el-avatar :size="150" src="/img/user.png"/>
        </div>

        <div class="userinfo" v-cloak>
          <p>用户账号</p>
          <p>{{ ucode.account }}</p>
        </div>

        <div class="userinfo">
          <p>用户昵称</p>
          <p>{{ ucode.username }}</p>
        </div>

        <div class="userinfo">
          <p>创建日期</p>
          <p>{{ ucode.add_time.slice(0,11) }}</p>
        </div>

      </div>
    </el-col>
    <el-col :span="18">
      <div class="ctent">
        <!--        <div class="item-title-line">-->
        <!--          基本信息-->
        <!--        </div>-->
        <el-tabs v-model="activeName" class="demo-tabs">
          <el-tab-pane label="基本资料" name="first">
            <el-form :model="userForm" label-width="120px" style="width: 60%;min-width: 450px">
              <el-form-item label="用户姓名">
                <el-input v-model="userForm.name"/>
              </el-form-item>
              <el-form-item label="用户性别">
                <el-radio-group v-model="userForm.sex">
                  <el-radio :label="'0'">女</el-radio>
                  <el-radio :label="'1'">男</el-radio>
                </el-radio-group>
              </el-form-item>
              <!--                保存按钮-->
              <div class="btn-box">
                <el-row class="mb-4">
                  <el-button color="#0052D9" @click="userSubimt">保 存</el-button>
                  <el-button type="info" @click="resetUser">重 置</el-button>
                </el-row>
              </div>
            </el-form>
          </el-tab-pane>
          <el-tab-pane label="修改密码" name="second" style="position: relative">
            <el-form :model="userPassword" label-width="120px" style="width: 60%;min-width: 450px"
                     ref="userFormRef"
                     :rules="userRules"
            >
              <el-form-item label="用户账号">
                <el-input v-model="ucode.account" disabled/>
              </el-form-item>
              <el-form-item label="原密码" prop="oldPass">
                <el-input v-model="userPassword.oldPass" type="password" autocomplete="off"/>
              </el-form-item>
              <el-form-item label="新密码" prop="pass">
                <el-input v-model="userPassword.pass" type="password" autocomplete="off"/>
              </el-form-item>
              <el-form-item label="再次输入新密码" prop="checkPass">
                <el-input
                    v-model="userPassword.checkPass"
                    type="password"
                    autocomplete="off"
                />
              </el-form-item>
              <!--                保存按钮-->
              <div class="btn-box">
                <el-row class="mb-4">
                  <el-button color="#0052D9" @click="passSubimt">保 存</el-button>
                  <el-button type="info" @click="resetPass">重 置</el-button>
                </el-row>
              </div>
            </el-form>
          </el-tab-pane>
        </el-tabs>
      </div>
    </el-col>
  </el-row>
</template>

<script>
import {ElMessage} from "element-plus";
import md5 from 'js-md5';
export default {
  name: "PersonalCenter",
  data() {
    var validateComfirmPwd = (rule, value, callback) => {
      if (this.userPassword.pass !== value) {
        callback(new Error('确认密码与新密码不一致！'))
      } else {
        callback()
      }
    }
    return {
      ucode: JSON.parse(sessionStorage.getItem('ucode')) || {},
      activeName: 'first',
      userForm: {
        name: '',
        sex: ''
      },
      userPassword: {
        pass: '',
        checkPass: '',
        oldPass:''
      },
      userRules: {
        oldPass:[{required: true, message: '请输入原密码', trigger: 'blur'}],
        pass: [
          {required: true, message: '请输入新密码', trigger: 'blur'},
        ],
        checkPass: [
          {required: true, message: '请再次输入新密码', trigger: 'blur'},
          {validator: validateComfirmPwd, trigger: 'blur'}
        ]
      }
    }
  },
  methods: {
    userSubimt() {
      vaildInterface("wechat/user.php","editUserInfo",{
        keyword:'editUserInfo',
        user_id:this.ucode.user_id,
        username:this.userForm.name,
        sex:this.userForm.sex
      },"01").then((res)=>{
        //数据返回成功
        if(res.success){
          ElMessage.success(res.msg);
        }else {
          ElMessage.error(res.msg);
        }
      }).catch((result=>{
        //数据返回失败
      }));
    },
    passSubimt() {
      this.$refs.userFormRef.validate((valid) => {
        if(valid) {
          vaildInterface("wechat/user.php","editPwd",{
            keyword:'editPwd',
            user_id:this.ucode.user_id,
            old_pwd:md5(this.userPassword.oldPass),
            new_pwd:md5(this.userPassword.pass),
          },"01").then((res)=>{
            //数据返回成功
            if(res.success){
              ElMessage.success(res.msg);
              this.logOut();
            }else {
              ElMessage.error(res.msg);
            }
          }).catch((result=>{
            //数据返回失败
          }));
        }
        // operation code
      })
    },
    resetUser() {
      this.getUserForm();
    },
    resetPass() {
      this.userPassword = {
        pass: '',
        checkPass: '',
        oldPass:''
      }
    },
    getUserForm(){
      let userInfo = JSON.parse(sessionStorage.getItem('ucode')) || '';
      if(userInfo){
        this.userForm.name=userInfo.username;
        this.userForm.sex=userInfo.sex;
      }
    },
  logOut() {
    sessionStorage.removeItem('routerUrl');
    sessionStorage.removeItem('ucode');
    this.$router.push('/');
  }
  },
  mounted() {
   this.getUserForm();
  }
}
</script>

<style scoped>
.item-car {
  background-color: #fff;
  border-radius: 3px;
  height: 100%;
  padding: 5px 20px;
  box-sizing: border-box;
  overflow-y: auto;
}

.ctent {
  width: 100%;
  margin: 0 auto;
  height: 100%;
  padding: 30px;
  box-sizing: border-box;
  background-color: #ffffff;
  border-radius: 10px;
  padding-top: 5px;
}

.user-icon {
  padding: 30px 0;
}

.userinfo {
  width: 100%;
  display: flex;
  color: #333333;
  font-weight: bold;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
}

.item-title-line {
  font-weight: bold;
  color: #333333;
  border-bottom: #e9e9e9 .5px solid;
  width: 100%;
  padding-bottom: 15px;
  padding-top: 10px;
}

.demo-tabs {
  --el-tabs-header-height: 48px;
  height: 100%;
}

.btn-box {
  display: flex;
  justify-content: center;
}
</style>